import Vue from 'vue';
import App from './App.vue';


Vue.config.productionTip = false;


// css
require('./assets/css/main.css');


// BootstrapVue
import {BootstrapVue} from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.use(BootstrapVue);


//VueCookies
import VueCookies from 'vue-cookies';

Vue.use(VueCookies);


// axios
import axios from 'axios';

Vue.prototype.$http = axios;
axios.defaults.headers.contentType = 'application/json';
axios.defaults.headers.accept = 'application/json';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.interceptors.request.use(config => {
        if (VueCookies.isKey('accessToken')) {
            config.headers.Authorization = 'Bearer ' + VueCookies.get("accessToken");
        } else {
            config.headers.Authorization = null;
        }
        return config;
    }, error => Promise.reject(error)
);

// FontAwesome
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

Vue.component('font-awesome-icon', FontAwesomeIcon);
import {library} from '@fortawesome/fontawesome-svg-core';
import {
    faAddressBook, faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faArrowUp19, faArrowUpAZ, faAt, faBars, faBell, faCalculator,
    faCalendar, faCalendarAlt, faCaretDown, faCartFlatbed, faCheck, faCheckCircle, faChevronDown, faChevronLeft, faChevronRight, faCircle, faClock,
    faCogs, faCopy, faCut, faDownload, faDraftingCompass, faEdit, faEnvelope, faEuroSign, faExclamationCircle, faEye, faFileDownload, faFingerprint,
    faFolderOpen, faKey, faLink, faList, faListUl, faLocationCrosshairs, faLock, faMagnifyingGlass, faMaximize, faMinus, faMinusCircle, faMousePointer,
    faPen, faPenRuler, faPlus, faPlusCircle, faPrint, faQrcode, faReceipt, faSearch, faShieldAlt, faSignInAlt, faSignOutAlt, faSort, faSortUp,
    faSortDown, faSquare, faSquareCheck, faSync, faTachometerAlt, faTable, faTasks, faThumbsUp, faToolbox, faTrashAlt, faTruck, faUnlink, faUpload,
    faUserCog, faUsersCog, faUserPlus, faXmark
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faAddressBook, faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight, faArrowUp19, faArrowUpAZ, faAt, faBars, faBell, faCalculator,
    faCalendar, faCalendarAlt, faCaretDown, faCartFlatbed, faCheck, faCheckCircle, faChevronDown, faChevronLeft, faChevronRight, faCircle, faClock,
    faCogs, faCopy, faCut, faDownload, faDraftingCompass, faEdit, faEnvelope, faEuroSign, faExclamationCircle, faEye, faFileDownload, faFingerprint,
    faFolderOpen, faKey, faLink, faList, faListUl, faLocationCrosshairs, faLock, faMagnifyingGlass, faMaximize, faMinus, faMinusCircle, faMousePointer,
    faPen, faPenRuler, faPlus, faPlusCircle, faPrint, faQrcode, faReceipt, faSearch, faShieldAlt, faSignInAlt, faSignOutAlt, faSort, faSortUp,
    faSortDown, faSquare, faSquareCheck, faSync, faTachometerAlt, faTable, faTasks, faThumbsUp, faToolbox, faTrashAlt, faTruck, faUnlink, faUpload,
    faUserCog, faUsersCog, faUserPlus, faXmark
);


// VueWorker
import VueWorker from 'vue-worker';

Vue.use(VueWorker);


// VueI18n
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);
import en from './lang/en.json';
import nl from './lang/nl.json';

const languages = {"en": {"translations": en}, "nl": {"translations": nl}};
const messages = Object.assign(languages);
const i18n = new VueI18n({
    locale: 'en',
    messages,
    fallbackLng: false
});


// VueRouter
import router from './router'


//Vue Select
import vSelect from 'vue-select';

Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';


// SketchUp
window.vm = {} // used to store current instance so that SketchUp can access it


new Vue({
    render: h => h(App),
    router,
    i18n
}).$mount('#app');
